import React from "react";
import { useHistory } from "react-router-dom";

function Success() {
  const history = useHistory();

  return (
    <div
      className="flex mx-auto px-auto py-auto h-screen bg-earth bg-cover"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/fia-background.jpg)`,
      }}
    >
      <section className="m-auto py-8 w-full md:w-1/2 lg:w-1/3 h-auto">
        <div className="mb-4 bg-white p-8 rounded text-black text-center">
          <p className="text-4xl">
            Thank you
            {history.location.firstName
              ? ", " + history.location.firstName
              : ""}
          </p>
          <p className="text-base mb-4">We'll be in touch soon.</p>
          <p className="text-base hover:underline font-semibold">
            <a href="https://www.fiafitnation.com.au">Visit FIA Fitnation</a>
          </p>
        </div>
      </section>
    </div>
  );
}

export default Success;
