import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import { Register, Landing, Success } from "./views";

function App() {
  return (
    <Switch>
      <PrivateRoute path="/register">
        <Register />
      </PrivateRoute>
      <PrivateRoute path="/success">
        <Success />
      </PrivateRoute>
      <Route path="/">
        <Landing />
      </Route>
    </Switch>
  );
}

function checkAuthenticated(location) {
  return location.referrerDetails || location.registrationSuccess;
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        checkAuthenticated(location) ? (
          children
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
}

export default App;
