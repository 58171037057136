import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

export default function Register() {
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationErrors, setRegistrationErrors] = useState();
  const [referrerDetails] = useState(history.location.referrerDetails);

  const courses = [
    "Certificate III in Fitness (SIS30315)",
    "Certificate IV in Fitness (SIS40215)",
    "PT Express (SIS30315 pre reqs only + SIS40215)",
    "Master Coach (SIS30315 + SIS40215)",
    "Nutrition Coach",
    "Diploma of Fitness (SIS50215)",
  ];

  const states = [
    "New South Wales",
    "Queensland",
    "Victoria",
    "South Australia",
    "Western Australia",
    "Northern Territory",
    "Tasmania",
    "Australian Capital Territory",
    "International",
  ];

  const registerUser = async (data) => {
    setIsRegistering(true);
    try {
      const referrerDetails = history.location.referrerDetails;

      const partner_referring_brand = referrerDetails.gym.brand.name;
      const partner_referring_club = referrerDetails.gym.name;
      const partner_referring_club_address = "address"; //not getting this from API
      const partner_referring_trainer = `${referrerDetails.first_name} ${referrerDetails.last_name}`;

      const payload = {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        phone: data.phone,
        state: data.state,
        zip: data.zip,
        course_interest_form: data.course_interest_form,
        partner_referring_brand: partner_referring_brand,
        partner_referring_club: partner_referring_club,
        partner_referring_club_address: partner_referring_club_address,
        partner_referring_trainer: partner_referring_trainer,
      };

      const apiUrl = `${process.env.REACT_APP_API_URL}/hubspot/`;

      const res = await axios({
        method: "POST",
        url: apiUrl,
        data: payload,
        headers: { "Content-Type": "application/json" },
      });

      if (res.status !== (await 201)) {
        alert("No good");

        if (res.data.details.errors) {
          setRegistrationErrors(res.data.details.errors[0].message);
        }

        throw Error("Couldnt register you");
      }

      setTimeout(() => {
        setRegistrationSuccess(true);
        setIsRegistering(false);
        history.push({
          pathname: "/success",
          registrationSuccess: true,
          firstName: data.firstname,
        });
      }, 2000);
    } catch (error) {
      setRegistrationErrors(
        "That didnt work - please check your details and resubmit"
      );
      window.scrollTo(0, 0);
      console.log(error);
      setIsRegistering(false);
    }
  };

  return (
    <div
      className="flex mx-auto px-auto py-auto h-full min-h-screen bg-earth bg-cover"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/images/fia-background.jpg')`,
      }}
    >
      <section className="m-auto py-8 w-full  md:w-1/2 lg:w-1/3 h-auto bg-white rounded mt-4 mb-4">
        {registrationSuccess ? (
          <div className="mb-4 bg-white p-8 rounded">
            <h2>It worked :)</h2>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(registerUser)}
            className="mb-4 bg-white p-8 pb-0 rounded"
          >
            <div className="mb-4 text-center">
              <div className="grid grid-cols-2 w-full gap-6 mb-6">
                <div className="w-full flex justify-center">
                  <img
                    src="/images/FIAFitnation logo_bw.jpg"
                    alt="fia logo"
                    className="h-12 object-contain"
                  />
                </div>
                <div className="w-full  flex justify-center">
                  <img
                    src={
                      referrerDetails.gym.brand.logo
                        ? referrerDetails.gym.brand.logo
                        : "/images/FIAFitnation logo_bw.jpg"
                    }
                    alt="fia logo"
                    className="h-12  object-contain"
                  />
                </div>
              </div>
              <h1 className="text-xl font-semibold">
                Register your interest in one of our&nbsp;courses
              </h1>
              {registrationErrors ? (
                <div
                  className="flex items-center bg-joy text-white text-sm font-bold px-4 py-3 w-full mb-8 mt-8 rounded"
                  role="alert"
                >
                  <svg
                    className="fill-current w-4 h-4 mr-2"
                    xmlns="https://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                  </svg>
                  <p>{registrationErrors}</p>
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                First Name
              </label>
              <input
                name="firstname"
                ref={register({ required: true })}
                className="text-box"
                placeholder="Your"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Last Name
              </label>
              <input
                name="lastname"
                ref={register({ required: true })}
                className="text-box"
                placeholder="Name"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">Email</label>
              <input
                name="email"
                ref={register({ required: true })}
                className="text-box"
                placeholder="youremail@email.com"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Phone Number
              </label>
              <input
                name="phone"
                ref={register({ required: true })}
                className="text-box"
                placeholder="0412345678"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Postcode
              </label>
              <input
                name="zip"
                ref={register({ required: true, minLength: 4, maxLength: 4 })}
                className="text-box"
                placeholder="2000"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm mb-2">
                Location
              </label>
              <select
                name="state"
                ref={register({ required: true, minLength: 2 })}
                className="text-box"
              >
                <option selected disabled value="">
                  Please select...
                </option>
                {states.map((state, index) => (
                  <option value={state} key={index}>
                    {state}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-8">
              <label className="block text-gray-700 text-sm mb-2">
                Which course are you interested in?
              </label>
              <select
                name="course_interest_form"
                ref={register({ required: true, minLength: 2 })}
                className="text-box"
              >
                <option selected disabled value="">
                  Please select...
                </option>
                {courses.map((course, index) => (
                  <option value={course} key={index}>
                    {course}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-5">
              <button
                type="submit"
                className="inline-block w-full py-4 px-8 leading-none text-white bg-red-500 hover:bg-red-600 rounded shadow"
              >
                {isRegistering ? (
                  <ClipLoader size={24} color={"#fff"} />
                ) : (
                  "Register"
                )}
              </button>
            </div>
          </form>
        )}
      </section>
    </div>
  );
}
