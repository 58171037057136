import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

function Landing() {
  let history = useHistory();
  let location = useLocation();

  const { register, handleSubmit, errors } = useForm();

  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    if (new URLSearchParams(location.search).get("code")) {
      validate({
        referralCode: new URLSearchParams(location.search).get("code"),
      });
    }
    // eslint-disable-next-line
  }, []);

  const validate = async (data) => {
    setIsValidating(true);

    try {
      setValidationError(false);

      const apiUrl = `${process.env.REACT_APP_API_URL}/validate/`;

      const res = await axios({
        method: "POST",
        url: apiUrl,
        data: {
          referral_code: data.referralCode.toUpperCase(),
        },
      });

      if (res.status === 200) {
        await localStorage.setItem(
          "referralCode",
          data.referralCode.toUpperCase()
        );

        setTimeout(() => {
          setIsValidating(false);
          history.push({
            pathname: "/register",
            referrerDetails: res.data.details,
          });
        }, 500);
      }
    } catch (error) {
      setValidationError(true);

      setIsValidating(false);
      console.log(error);
    }

    return isValidating;
  };

  return (
    <div
      className="flex mx-auto px-auto py-auto h-screen bg-earth bg-cover"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/fia-background.jpg)`,
      }}
    >
      <section className="m-auto py-8 w-full md:w-1/2 lg:w-1/3 h-auto">
        <form
          onSubmit={handleSubmit(validate)}
          className="mb-4 bg-white p-8 rounded"
        >
          <div className="flex justify-center w-full md:w-1/2 m-auto mb-6">
            <img src="/images/FIAFitnation logo_bw.jpg" alt="fia logo" />
          </div>
          {isValidating ? (
            <div className="w-full flex justify-center">
              <ClipLoader size={48} color={"#000"} />
            </div>
          ) : (
            <>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm mb-2">
                  Enter your 6 digit referral code
                </label>
                <input
                  name="referralCode"
                  ref={register({
                    required: true,
                    minLength: 6,
                    maxLength: 6,
                    message: "Must be 6 characters",
                  })}
                  className="block w-full py-3 px-4 lead-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"
                  placeholder="8QLTP2"
                />
                <p className="text-sm text-center text-gray-500 mb-4 mt-4">
                  {errors.referralCode
                    ? "Invalid code - it must be a combination of 6 letters and number "
                    : ""}
                </p>
                {validationError ? (
                  <p className="text-sm text-center text-gray-500 mb-4">
                    Invalid code
                  </p>
                ) : null}
              </div>
              <div className="mb-5">
                <button
                  type="submit"
                  className="inline-block w-full py-4 px-8 leading-none text-white bg-red-500 hover:bg-red-600 rounded shadow"
                  disabled={isValidating || errors.referralCode}
                >
                  Check Code
                </button>
              </div>{" "}
            </>
          )}
        </form>
      </section>
    </div>
  );
}

export default Landing;
